
export const PLANTING_STATUS = {
    PLANNED: "planned",
    SOWN: "sown",
    TRANSPLANTED: "transplanted",
    HARVESTABLE: "harvestable",
    HARVESTED: "harvested"
}

export const PLANTING_STATUS_LABELS = {
    [PLANTING_STATUS.PLANNED]: "In planning",
    [PLANTING_STATUS.SOWN]: "Gezaaid",
    [PLANTING_STATUS.TRANSPLANTED]: "Geplant",
    [PLANTING_STATUS.HARVESTED]: "Geoogst"
}

export const GENERIC_ERROR_MESSAGE = "Sorry, er ging iets fout. Controleer wat je hebt ingevulden probeer het (later) opnieuw."

export const monthOptions = [
        {value: 1, label: 'Jan'},
        {value: 2, label: 'Feb'},
        {value: 3, label: 'Mrt'},
        {value: 4, label: 'Apr'},
        {value: 5, label: 'Mei'},
        {value: 6, label: 'Jun'},
        {value: 7, label: 'Jul'},
        {value: 8, label: 'Aug'},
        {value: 9, label: 'Sep'},
        {value: 10, label: 'Oct'},
        {value: 11, label: 'Nov'},
        {value: 12, label: 'Dec'},
        {value: 0, label: '-'},
    ]


export const LOOM_VIDEOS = [
        {
            pathname: "/payment/success",
            loom: "https://www.loom.com/embed/aa3f9270ed7c4805b01c2245040ccb9b?sid=a0d5b6cd-5685-4441-891a-9dee29e5c6d1"
        },
        {
            pathname: "/welcome",
            loom: "https://www.loom.com/embed/aa3f9270ed7c4805b01c2245040ccb9b?sid=a0d5b6cd-5685-4441-891a-9dee29e5c6d1"
        },
        {
            pathname: "/gardens",
            loom: "https://www.loom.com/embed/12184292514647fcbf5eb31f416357f9?sid=dd666e40-e7d1-4aa5-bb59-d54f7aed35b1"
        },
        {
            pathname: "/planner",
            loom: "https://www.loom.com/embed/812a9cb9a96a4b30969249c8bc470ffb?sid=c0217e20-4db0-417c-a8a0-31e33954684a"
        },
        {
            pathname: "/profile",
            loom: "https://www.loom.com/embed/b09ce9ccc65a47eb89359a8f308e91e1?sid=3e309209-6dae-4006-8eaa-fc3d7d98ffe5"
        },
        {
            pathname: "/crops",
            loom: "https://www.loom.com/embed/89bf590b443743ea8e5e5a7d7072364c?sid=13c099e6-0d62-4efb-920c-08ca95f317f2"
        },
        {
            pathname: "/updates",
            loom: "https://www.loom.com/embed/ee5dff101bda4a80af04da97da41d2bc?sid=0bc67ad8-69b8-442f-9a77-a15a5c1ef7c5"
        }
    ]