import {Button, Checkbox, Col, Form, Input, notification, Row} from "antd";
import React from "react";
import api from "../../api";
import './style.css';
import {ArrowRightOutlined} from "@ant-design/icons";
import {useNavigate, useSearchParams} from "react-router-dom";
import {useTranslations} from "../../lang";

export default function Register() {

    const [notificationApi, contextHolder] = notification.useNotification();
    const query = new URLSearchParams(window.location.search)
    const {translate} = useTranslations()
    const navigate = useNavigate()

    const onFinish = (values) => {

        if (!values.tos) {
            notificationApi.error({
                message: translate('Je moet akkoord gaan met de algemene voorwaarden en privacy policy!'),
                description: translate('Lees de algemene voorwaarden en privacy policy en vink het vakje aan.'),
                placement: 'top',
                duration: 3
            });
            return
        }

        api.register(
            values.email,
            values.name,
            values.password,
            values.company,
            query.get('utm_source'),
            query.get('utm_medium'),
            query.get('utm_campaign'),
        ).then((response) => {
            if (response.token) {
                localStorage.setItem('token', response.token);
                window.location.href = response.url;
            }
        }).catch((error) => {
            if (error.response.data.detail && error.response.data.detail == "UserAlreadyExists") {
                notificationApi.error({
                    message: translate(`Dit emailadres is al in gebruik!`),
                    description: translate('Probeer in te loggen met dit emailadres of gebruik een ander emailadres.'),
                    placement: 'top',
                    duration: 3
                });
                return
            }

            notificationApi.error({
                message: translate(`Woops! Er is iets misgegaan! 🥕`),
                description: translate('Check of je alles goed hebt ingevuld en probeer het later nog eens. Lukt het nog steeds niet? Neem contact op met ons.'),
                placement: 'top',
                duration: 3
            });
        })
    }

    return (
        <>
            <Form
                name="register_form"
                className="register-form"
                initialValues={{
                    remember: true,
                }}
                onFinish={onFinish}>
                <Row justify="center">
                    {contextHolder}
                    <Col md={6} xs={24}>
                        <h1>{translate('Registreer vandaag nog!')}</h1>
                        <p>{translate('Begin vandaag nog met het plannen van jouw moestuin.')}</p>

                        <h2>{translate('Over jou')}</h2>
                        <Form.Item
                            name="name"
                            rules={[
                                {
                                    required: true,
                                    message: translate('Vul je voor en achternaam in'),
                                },
                            ]}
                        >
                            <Input placeholder={translate('Voor- en achternaam')}/>
                        </Form.Item>
                        <Form.Item
                            name="email"
                            rules={[
                                {
                                    required: true,
                                    message: translate('Vul een emailadres in'),
                                },
                                {
                                    type: 'email',
                                    message: translate('Vul een geldig emailadres in'),
                                },
                            ]}
                        >
                            <Input placeholder={translate('E-mail address')}/>
                        </Form.Item>
                        <h2>{translate('Voor je account')}</h2>
                        <Form.Item
                            name="password"
                            rules={[
                                {
                                    required: true,
                                    message: translate('Voer een wachtwoord in!'),
                                },
                            ]}
                        >
                            <Input.Password placeholder={translate('Wachtwoord')}/>
                        </Form.Item>
                        <Form.Item
                            name="password_confirm"
                            rules={[
                                {
                                    required: true,
                                    message: translate('Voer een wachtwoord in!'),
                                },
                                ({getFieldValue}) => ({
                                    validator(_, value) {
                                        if (!value || getFieldValue('password') === value) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(new Error(translate('De wachtwoorden die je hebt ingevuld komen niet overeen!')));
                                    },
                                }),
                            ]}
                        >
                            <Input.Password placeholder={translate('Herhaal wachtwoord')}/>
                        </Form.Item>
                        <h2>{translate('Over jouw tuin')}</h2>
                        <Form.Item
                            name="company"
                            rules={[
                                {
                                    required: true,
                                    message: translate('Vul de naam van je tuin in, bijvoorbeeld: "Stef\'s moestuin" of "Tuinderij de brocoli"'),
                                },
                            ]}>
                            <Input placeholder={translate('Naam van je tuin(derij)')}/>
                        </Form.Item>

                        <Form.Item name={"tos"}  valuePropName="checked" rules={[{ required: true, message: translate('Je moet akkoord gaan met de algemene voorwaarden en privacy policy!') }]}>
                            <Checkbox>
                                {translate('Ik ga akkoord met de ')} <a href="https://www.oogst.app/tos" target={"_blank"}>{translate('algemene voorwaarden')}</a> {translate('en')}
                                <a target={"_blank"} href="https://www.oogst.app/privacy-policy">{translate('privacy policy')}</a>.</Checkbox>
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={24} className={"text-center"}>
                        <Form.Item>
                            <Button type="primary" size={"large"} htmlType="submit" className="login-form-button">
                                {translate('Aanmelden en direct beginnen')} <ArrowRightOutlined/>
                            </Button>
                        </Form.Item>
                    </Col>
                </Row>

                <Row>
                    <Col span={24} className={"text-center"}>
                        <Button type={"link"} onClick={() => navigate('/login')}>{translate('Terug naar inloggen')}</Button>
                    </Col>
                </Row>
            </Form>
        </>
    );
}