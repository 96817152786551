// export function calculateMaxPlantsInBed(length, width, plantRowDistance, plantDistanceInRow) {
//   // Calculate the number of plants based on rows along the length
//   let rowsLength = Math.floor(length / plantRowDistance);
//   let plantsInRowLength = Math.floor(width / plantDistanceInRow);
//   let totalPlantsLength = rowsLength * plantsInRowLength;
//
//   // Calculate the number of plants based on rows along the width
//   let rowsWidth = Math.floor(width / plantRowDistance);
//   let plantsInRowWidth = Math.floor(length / plantDistanceInRow);
//   let totalPlantsWidth = rowsWidth * plantsInRowWidth;
//
//   // Return the maximum number of plants
//   return Math.max(totalPlantsLength, totalPlantsWidth);
// }
//

import dayjs from "dayjs";
import {LOOM_VIDEOS, PLANTING_STATUS, PLANTING_STATUS_LABELS} from "./constants";

export function calculateMaxPlantsInBed(length, width, plantRowDistance, plantDistanceInRow) {
    // Number of rows we can have in the bed
    const rows = Math.floor(length / plantRowDistance);

    // Number of plants we can have in a row
    const plantsInRow = Math.floor(width / plantDistanceInRow);

    // Total number of plants
    const totalPlants = rows * plantsInRow;

    return totalPlants;
}


export function getFirstSowableDate(crop) {
    let month_min= crop.sow_min
    let month_max = crop.sow_max;

    if (crop.is_seedling) {
        month_min = crop.plant_min
        month_max = crop.plant_max
    }

    const today = dayjs();
    const currentYear = today.year();
    const currentMonth = today.month() + 1; // dayjs months are 1-based

    if (month_min > month_max) {
        throw new Error("month_min should be less than or equal to month_max");
    }

    if (currentMonth >= month_min && currentMonth <= month_max) {
        return today; // Today is within the range
    }

    // Find the first available month in the range
    for (let month = month_min; month <= month_max; month++) {
        if (month > currentMonth) {
            return dayjs(`${currentYear}-${month}-01`);
        }
    }

    // If all months are in the past, return the first valid month next year
    return dayjs(`${currentYear + 1}-${month_min}-01`);
}

/**
 *     is_direct_sown = Column(Boolean, default=True)
 *     is_pricked_out = Column(Boolean, default=False)
 *     is_multiple_harvestable = Column(Boolean, default=False)
 *
 *     buffer_days = Column(Integer, nullable=True)
 *     germination_days = Column(Integer, nullable=True)
 *     prick_out_days = Column(Integer, nullable=True)
 *     plant_out_days = Column(Integer, nullable=True)
 *     maturity_days = Column(Integer, nullable=True)
 *     harvest_days = Column(Integer, nullable=True)
 *     harvest_count = Column(Integer, nullable=True)
 *
 */

function calculateCropDays(crop) {

    let plant_min = crop.plant_min ? crop.plant_min : crop.sow_min;

    if (crop.is_seedling) {
        crop.sow_min = crop.plant_min
        crop.sow_max = crop.plant_max
    }

    let harvestDates = [];
    let daysToPrick = 0;
    let daysToTransplant = (plant_min - crop.sow_min) * 31;
    let daysToMature = (crop.harvest_min - plant_min) * 31;
    let daysToLastHarvest = (crop.harvest_max - crop.harvest_min) * 31;

    if (crop.is_fully_configured) {
        if (crop.is_direct_sown) {
            daysToPrick = 0;
            daysToTransplant = 0;
        } else {
            daysToPrick = crop.prick_out_days ? crop.prick_out_days : 0;
            daysToTransplant = crop.plant_out_days;
        }

        daysToMature = crop.maturity_days;
        if (!crop.is_multiple_harvestable) {
            daysToLastHarvest = 0
        } else {
            for (let i = 1; i < crop.harvest_count; i++) {
                harvestDates.push((crop.harvest_days * i))
            }
            daysToLastHarvest = (crop.harvest_days * crop.harvest_count);
        }
    }

    return {
        daysToGerminate: parseInt(crop.germination_days ? crop.germination_days : 0),
        daysToPrick: parseInt(daysToPrick),
        daysToTransplant: parseInt(daysToTransplant),
        daysToMature: parseInt(daysToMature),
        daysToLastHarvest: parseInt(daysToLastHarvest),
        harvestDates: harvestDates
    }
}

export function calculateCropDates(crop, user_sow_at) {

    const sow_at = user_sow_at ? user_sow_at : getFirstSowableDate(crop);

    const {
        daysToGerminate,
        daysToPrick,
        daysToTransplant,
        daysToMature,
        daysToLastHarvest,
        harvestDates
    } = calculateCropDays(crop);

    return {
        sow_at: sow_at,
        germinate_at: sow_at.add(daysToGerminate, 'days'),
        prick_at: sow_at.add(daysToGerminate + daysToPrick, 'days'),
        transplant_at: sow_at.add(daysToGerminate + daysToPrick + daysToTransplant, 'days'),
        harvest_at: sow_at.add(daysToGerminate + daysToPrick + daysToTransplant + daysToMature, 'days'),
        harvest_until: sow_at.add(daysToGerminate + daysToPrick + daysToTransplant + daysToMature + daysToLastHarvest, 'days'),
        harvest_dates: harvestDates.map((days) => sow_at.add(daysToGerminate + daysToPrick + daysToTransplant + daysToMature + days, 'days'))
    }
}

export function calculateCropDatesFromPlanting(crop, user_transplant_date) {
    const transplant_at = user_transplant_date

    const {
        daysToPrick,
        daysToTransplant,
    } = calculateCropDays(crop);

    const sow_at = transplant_at.subtract(daysToPrick + daysToTransplant, 'days')
    return calculateCropDates(crop, sow_at)
}

export function calculateCropDatesFromHarvest(crop, user_harvest_date) {

    const harvest_at = user_harvest_date;

    const {
        daysToGerminate,
        daysToPrick,
        daysToTransplant,
        daysToMature,
    } = calculateCropDays(crop);

    const sow_at = harvest_at.subtract(daysToMature + daysToPrick + daysToTransplant + daysToGerminate, 'days')
    return calculateCropDates(crop, sow_at)
}

export function calculateRowsAndPlants(bed, crop) {

    const rows = Math.floor(bed.width / crop.row_distance);
    const plants = Math.floor(bed.length / crop.plant_distance);

    return {
        rows: rows == 0 ? 1 : rows,
        plants_per_row: plants == 0 ? 1 : plants
    }
}

export function bedDimensionsToHuman(bed) {

    let width = bed.width
    let widthUnit = "cm"

    let length = bed.length
    let lengthUnit = "cm"

    if (bed.width > 100) {
        width = bed.width / 100
        widthUnit = "m"
    }
    if (bed.length > 100) {
        length = bed.length / 100
        lengthUnit = "m"
    }


    return `${width}${widthUnit}x${length}${lengthUnit}`
}


export function filterPlanting(p, endDate, hideHarvested) {
    if (!hideHarvested) {
        hideHarvested = localStorage.getItem('hideHarvested') === 'true'
    }

    // Filter out everything without a crop
    if (!p.crop) {
        return false
    }

    if (!p.harvest_at && !p.sow_at && !p.transplant_at) {
        return true
    }

    if (hideHarvested && p.status === PLANTING_STATUS.HARVESTED) {
        return false
    }

    return true
}

export function getVideoForPage(location) {
    const video = LOOM_VIDEOS.filter((video) => {
        return location.pathname.startsWith(video.pathname)
    })

    if (video.length > 0) {
        return video[0].loom
    }
    return null
}

export function printPrice(price) {
    return `€ ${price.toFixed(2).replace('.', ',')}`
}