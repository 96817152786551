import React, {useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import api from "../../api";
import {Button, Col, Form, Input, message, Modal, Row, Spin, Table, Tabs} from "antd";

export default function AdminIndex() {
    const [users, setUsers] = useState([]);
    const [blogPosts, setBlogPosts] = useState([]);
    const [blogPostIdeads, setBlogPostIdeads] = useState([]);
    const [ideasLoading, setIdeasLoading] = useState(false);
    const [generateIdeasModalOpen, setGenerateIdeasModalOpen] = useState(false);
    const [stats, setStats] = useState(false);
    const [generateIdeasForm, setGenerateIdeasForm] = Form.useForm();
    const [searchForm, setSearchForm] = Form.useForm();

    useEffect(() => {
        api.getAdminStats().then(setStats)
    }, []);

    const searchUser = () => {
        api.searchUser(searchForm.getFieldValue('email')).then((users) => {
            setUsers(users)
        })
    }

    useEffect(() => {
        api.get_all_blog_posts().then((posts) => {
            setBlogPosts(posts)
        })
    }, []);

    const generateBlogPostIdeas = () => {
        setIdeasLoading(true)
        api.generate_blog_post_ideas(generateIdeasForm.getFieldValue('prompt')).then((ideas) => {
            setBlogPostIdeads(ideas)
            setIdeasLoading(false)
        })
    };


    const clickAddBlogPost = (idea) => {

        api.addBlogPost({
            prompt: idea,
            meta_keywords: generateIdeasForm.getFieldValue('meta_keywords')
        }).then(() => {
            message.success('Blog post added')
        })
    }


    const addAll = () => {
        blogPostIdeads.forEach((idea) => {
            clickAddBlogPost(idea)
        })
    }

    return (
        <>
            <Modal open={generateIdeasModalOpen} onCancel={() => setGenerateIdeasModalOpen(false)} footer={null}
                   width={"80%"}>
                <Form form={generateIdeasForm} layout={"vertical"}>
                    <Form.Item name={"prompt"} label={"Geef sugggesties over"}>
                        <Input onPressEnter={() => generateBlogPostIdeas()}/>
                    </Form.Item>
                    <Form.Item name={"meta_keywords"} label={"Keywords"}>
                        <Input onPressEnter={() => generateBlogPostIdeas()}/>
                    </Form.Item>
                    <Form.Item>
                        <Button onClick={() => generateBlogPostIdeas()}>Generate ideas</Button>
                    </Form.Item>
                </Form>
                {ideasLoading && (
                    <Spin/>
                )}
                {!ideasLoading && blogPostIdeads.length > 0 && (
                    <>
                        <ol>
                            {blogPostIdeads.map((idea) => (
                                <li key={idea}>
                                    <Button onClick={() => clickAddBlogPost(idea)} type={"link"}>{idea}</Button>
                                </li>
                            ))}
                        </ol>
                        <Button onClick={() => addAll()}>Add All</Button>
                    </>
                )}
            </Modal>
            <h1>Admin</h1>
            {stats && (
                <Row>
                    <Col className={"text-center"} xs={12} md={4}>
                        <h1>Paying Users</h1>
                        <h2>{stats.paying_users.toLocaleString()}</h2>
                    </Col>
                    <Col className={"text-center"} xs={12} md={4}>
                        <h1>Revenue</h1>
                        <h2>€ {stats.total_revenue.toLocaleString()}</h2>
                    </Col>
                    <Col className={"text-center"} xs={12} md={4}>
                        <h1>Companies</h1>
                        <h2>{stats.total_companies.toLocaleString()}</h2>
                    </Col>
                    <Col className={"text-center"} xs={12} md={4}>
                        <h1>Beds</h1>
                        <h2>{stats.total_beds.toLocaleString()}</h2>
                    </Col>
                    <Col className={"text-center"} xs={12} md={4}>
                        <h1>Crops</h1>
                        <h2>{stats.total_crops.toLocaleString()}</h2>
                    </Col>
                    <Col className={"text-center"} xs={12} md={4}>
                        <h1>Plantings</h1>
                        <h2>{stats.total_plantings.toLocaleString()}</h2>
                    </Col>
                </Row>
            )}
            <Tabs defaultActiveKey="1" items={[
                {
                    label: 'Users', key: '1', children: (
                        <Row>
                            <Col span={24}>
                                <h2>Users</h2>
                                <Form form={searchForm}>
                                    <Form.Item label={"Search by email"} name={"email"}>
                                        <Input onPressEnter={searchUser}/>
                                    </Form.Item>
                                </Form>
                            </Col>
                            <Col span={24}>
                                {users.length > 0 && (

                                    <Table style={{width: '100%'}} dataSource={users} columns={[
                                        {title: 'ID', dataIndex: 'id', key: 'id'},
                                        {title: 'Name', dataIndex: 'name', key: 'name'},
                                        {title: 'Email', dataIndex: 'email', key: 'email'},
                                        {
                                            title: 'Actions', key: 'actions', render: (user) => (
                                                <Link to={`/admin/impersonate/${user.id}`}>Impersonate</Link>
                                            )
                                        },
                                    ]} pagination={false}></Table>
                                )}
                            </Col>
                        </Row>
                    )
                },
                {
                    label: 'Blog Posts', key: '2', children: (
                        <Row>
                            <Col span={24}>
                                <h1>Blog posts</h1>
                                <Button onClick={() => setGenerateIdeasModalOpen(true)} type={"default"}>
                                    Generate Ideas
                                </Button>

                                <Table pagination={false} dataSource={blogPosts} columns={[
                                    {
                                        title: 'Generated',
                                        dataIndex: 'is_generated',
                                        key: 'is_generated',
                                        render: (value) => value ? 'Yes' : 'No'
                                    },
                                    {
                                        title: 'Date',
                                        dataIndex: 'published_at',
                                        key: 'published_at',
                                        render: (value) => value ? new Date(value).toLocaleDateString() : 'Not published'
                                    },
                                    {title: 'Prompt', dataIndex: 'prompt', key: 'prompt'},
                                    {title: 'Meta Keywords', dataIndex: 'meta_keywords', key: 'meta_keywords'},
                                    {
                                        title: 'View',
                                        dataIndex: 'slug',
                                        key: 'slug',
                                        render: (id) => <Link target={"_blank"} to={api.get_blog_url(id)}>View</Link>
                                    },
                                ]}/>
                            </Col>
                        </Row>
                    )
                },
            ]}>

            </Tabs>


        </>
    )
}