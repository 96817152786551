import React, {useEffect, useRef, useState} from 'react';
import './style.css';
import api from "../../api";
import {useNavigate, useParams} from "react-router-dom";
import {Alert, Button, Col, Row} from "antd";
import PlantingPreview from "../../Components/PlantingPreview";
import {ZoomInOutlined, ZoomOutOutlined} from "@ant-design/icons";
import {useTranslations} from "../../lang";

const GardenMap = () => {
    const [bedPositions, setBedPositions] = useState([]);
    const snapToGrid = 5;
    const containerRef = useRef(null);

    const localZoom = localStorage.getItem('zoom');
    const [zoom, setZoom] = useState(localZoom ? parseFloat(localZoom) : 1);
    const {translate} = useTranslations();
    const navigate = useNavigate();
    const {visiblePlantings, setVisiblePlantings} = useState({});



    const {id} = useParams();
    useEffect(() => {
        api.garden(id).then((garden) => {
            setBedPositions(garden.beds.map(((bed, i) => ({
                ...bed,
                left: bed.left ? bed.left : snapToGrid,
                top: bed.top ? bed.top : (i * bed.width + i * snapToGrid)
            }))));
        });
    }, []);

    const handlePointerDown = (index, e) => {
        e.preventDefault(); // Prevents default touch behavior like scrolling
        containerRef.current.style.touchAction = 'none'; // Disables touch scrolling on the container
        startDragging(index, e);
    };

    const getVisiblePlanting = (bed) => {
        if(visiblePlantings[bed.id]){
            return bed.plantings[visiblePlantings[bed.id]];
        }

        const non_harvested = bed.plantings.filter(planting => planting.status !== "harvested");
        if(non_harvested.length === 0){
            return null;
        }
        return non_harvested[0];
    }

    const startDragging = (index, e) => {
        e.preventDefault();
        document.body.style.overflow = 'hidden'; // Disable body scrolling

        const containerRect = containerRef.current.getBoundingClientRect();
        const startX = ((e.clientX || e.touches[0].clientX) - containerRect.left - bedPositions[index].left * zoom) / zoom;
        const startY = ((e.clientY || e.touches[0].clientY) - containerRect.top - bedPositions[index].top * zoom) / zoom;

        const onPointerMove = (e) => {
            e.preventDefault();
            const updatedBeds = [...bedPositions];
            let newLeft = ((e.clientX || e.touches[0].clientX) - containerRect.left) / zoom - startX;
            let newTop = ((e.clientY || e.touches[0].clientY) - containerRect.top) / zoom - startY;

            if (newLeft < 0) {
                newLeft = 0;
            }
            if (newTop < 0) {
                newTop = 0;
            }

            if (snapToGrid > 0) {
                newLeft = Math.round(newLeft / snapToGrid) * snapToGrid;
                newTop = Math.round(newTop / snapToGrid) * snapToGrid;
            }

            updatedBeds[index].left = newLeft;
            updatedBeds[index].top = newTop;
            setBedPositions(updatedBeds);
        };

        const onPointerUp = () => {
            containerRef.current.style.touchAction = ''; // Disables touch scrolling on the container
            document.removeEventListener('mousemove', onPointerMove);
            document.removeEventListener('mouseup', onPointerUp);
            document.removeEventListener('touchmove', onPointerMove);
            document.removeEventListener('touchend', onPointerUp);

            api.setBedPositions(id, bedPositions.map((bed) => ({
                left: bed.left,
                top: bed.top,
                id: bed.id
            })));

            document.body.style.overflow = 'hidden'; // Disable body scrolling
        };

        document.addEventListener('mousemove', onPointerMove);
        document.addEventListener('mouseup', onPointerUp);
        document.addEventListener('touchmove', onPointerMove);
        document.addEventListener('touchend', onPointerUp);
    };

    const getRotationAngle = (bed) => {
        return bed.rotation || 0;
    };

    function saveZoom(newZoom){
        localStorage.setItem('zoom', newZoom);
        setZoom(newZoom)
    }

    return (
        <div>
            <Row>
                <Col span={24}>
                    <Alert description={<div>
                        <p>Let op! Aan deze functie wordt nog gewerkt! We werken onder andere nog aan:</p>
                        <ul>
                            <li>Ondersteuning voor mobiele apparaten</li>
                            <li>Goed weergeven van opvolging (de blokjes lopen nu over)</li>
                            <li>De kaart door de tijd heen kunnen bekijken</li>
                            <li>Icoontjes en pictogrammen en betere leesbaarheid</li>
                            <li>Algemene snelheid, de kaart voelt wat traag aan</li>
                        </ul>
                        <p>Heb je feedback? Stuur gerust even een mailtje naar mail@oogst.app! :) </p>
                    </div>}></Alert>
                    <hr/>
                    <Button onClick={() => navigate(`/planner/${id}`)}>{translate('Terug naar planner')}</Button>
                    <Button onClick={() => saveZoom(zoom + 0.1)}><ZoomInOutlined/></Button>
                    <Button onClick={() => saveZoom(zoom - 0.1)}><ZoomOutOutlined/></Button>
                    <hr/>
                </Col>
            </Row>
            <div ref={containerRef} className="bed-container">
                {bedPositions.map((bed, index) => (
                    <div
                        key={bed.name}
                        className="bed"
                        style={{
                            height: `${bed.width * zoom}px`,
                            width: `${bed.length * zoom}px`,
                            top: `${bed.top * zoom}px`,
                            left: `${bed.left * zoom}px`,
                            transform: `rotate(${getRotationAngle(bed)}deg)`,
                        }}
                        onMouseDown={(e) => handlePointerDown(index, e)}
                        onTouchStart={(e) => handlePointerDown(index, e)}
                    >
                        <div className={"bed-controls"} style={{marginTop: '-35px', display: 'block', height: '35px', lineHeight: '25px'}}>
                            <span className={"bed-name"}>{bed.name}</span>
                            <span></span>
                        </div>

                        {bed.plantings.filter(planting => planting.status !== "harvested").map((planting) => {
                            return (
                                <PlantingPreview label={planting.crop.name + ' - ' + planting.crop.variety} key={planting.id} bedWidth={bed.length * zoom} bedLength={bed.width*zoom} plantsPerRow={planting.plants_per_row} numRows={planting.rows} />
                            )
                        })}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default GardenMap;