import React, {useEffect, useState} from 'react';
import {
    Button,
    Col,
    Form,
    Input,
    Modal,
    Row,
    Spin,
    message,
    InputNumber,
    Select,
    Alert,
    Switch,
    Image,
    Checkbox, Tooltip
} from "antd";
import api from "../../api";
import './style.css'
import CropTiming from "../../Components/CropTiming";
import {
    DeleteOutlined,
    EditOutlined,
    ExportOutlined,
    SettingOutlined
} from "@ant-design/icons";
import SearchCropModal from "../../Components/SearchCropModal";
import {useNavigate} from "react-router-dom";
import {monthOptions, PLANTING_STATUS_LABELS} from "../../constants";
import LoomVideo from "../../Components/LoomVideo";
import {useTranslations} from "../../lang";
import TextArea from "antd/lib/input/TextArea";
import CropImage from "../../Components/CropImage";
import {useAuth} from "../../auth";

const {confirm} = Modal;

export default function Crops() {
    const [messageApi, contextHolder] = message.useMessage();
    const [crops, setCrops] = useState([])
    const [loading, setLoading] = useState(true)
    const [addCropModalOpen, setAddCropModalOpen] = useState(false)
    const [editCropModalOpen, setEditCropModalOpen] = useState(false)
    const [editingCrop, setEditingCrop] = useState(null);
    const [editCropForm] = Form.useForm();
    const [filterCropForm] = Form.useForm();
    const [uploadedFile, setUploadedFile] = React.useState(null);
    const navigate = useNavigate();
    const query = new URLSearchParams(window.location.search).get('search')
    const cropId = new URLSearchParams(window.location.search).get('cropId')
    const [isFiltering, setIsFiltering] = useState(false)
    const [reloadingCropData, setReloadingCropData] = useState(false)
    const {translate} = useTranslations()
    const {plan} = useAuth()


    const [isSeedling, setIsSeedling] = useState(false);

    const [isDirectSown, setIsDirectSown] = useState(false);

    function setTogglesFromCrop(crop) {
        console.log(crop.is_seedling)
        setIsSeedling(crop.is_seedling);
        setIsDirectSown(crop.is_direct_sown);
    }

    function handleUpload(event) {
        event.preventDefault();
        api.uploadToCdn(event.target.files[0]).then(setUploadedFile);
    }

    function loadCrops(openCrop = false) {
        setLoading(true)
        const filters = filterCropForm.getFieldsValue()

        setIsFiltering(Object.keys(filters).length > 0)

        api.crops(filters).then((allCrops) => {
            setCrops(allCrops)

            if(cropId && openCrop === true){
                console.log(openCrop)
                const crop = allCrops.find(c => c.id === parseInt(cropId))
                handleCropClick(crop)
            }

        }).finally(() => {
            setLoading(false)
        })

        if (query) {
            setAddCropModalOpen(true)
        }
    }

    useEffect(() => {
        loadCrops(true)
    }, [])

    function handleCropClick(crop) {
        setUploadedFile(crop.image)
        editCropForm.setFieldsValue(crop)
        setEditingCrop(crop)
        setEditCropModalOpen(true)
        setTogglesFromCrop(crop)
    }

    function handleEditModalClose() {
        setEditCropModalOpen(false)
        setEditingCrop(null)
        editCropForm.resetFields()
        setUploadedFile(null)
        loadCrops()
    }

    function toggleStock(crop, inStock) {
        const cropData = {...crop}
        cropData.in_stock = inStock
        api.updateCrop(crop.id, cropData)
    }

    function handleCropSave() {
        const values = editCropForm.getFieldsValue()
        if (editingCrop === null) {
            values.image = uploadedFile
            api.createCrop(values).then(() => {
                handleEditModalClose()
                loadCrops()
            }).catch(error => {
                if (error.response.data.error === "CropLimitExceededException") {
                    Modal.error({
                        title: translate("Je hebt het maximum aantal gewassen bereikt"),
                        okText: translate("Ga naar account instellingen"),
                        onOk: () => navigate('/account'),
                        content: (
                            <div>
                                <p>{translate("Je hebt het maximum aantal gewassen bereikt voor je account.")}</p>
                                <p>{translate("Wil je meer gewassen toevoegen? Upgrade dan.")}</p>
                            </div>
                        )
                    })
                    return
                }
            })
        } else {
            values.image = uploadedFile
            api.updateCrop(editingCrop.id, values).then(() => {
                handleEditModalClose()
                message.success(translate('De gegevens van het gewas zijn aangepast!'))
            })
        }
    }

    function deleteCrop(crop) {

        confirm({
            title: translate('Weet je zeker dat je dit gewas wilt verwijderen?'),
            content: translate('Het gewas zal ook uit je planning verwijderd worden. Dit kan niet ongedaan worden gemaakt!'),
            onOk() {
                api.deleteCrop(crop.id).then((res) => {
                    if (res) {
                        messageApi.open({
                            type: 'success',
                            content: translate('Het gewas is verwijderd'),
                        });
                    } else {
                        messageApi.open({
                            type: 'error',
                            content: translate('Kan het gewas niet verwijderen, het is nog in gebruik. Verwijder het eerst uit je planner'),
                        });
                    }
                    loadCrops()
                })
            },
            onCancel() {
            },
        });


    }

    return (
        <>
            {contextHolder}
            <SearchCropModal
                addCropModalOpen={addCropModalOpen}
                onCancel={() => setAddCropModalOpen(false)}
                onCropFound={(crop) => {
                    setEditCropModalOpen(true)
                    editCropForm.setFieldsValue(crop)
                    setUploadedFile(crop.image)
                    setTogglesFromCrop(crop)
                    setAddCropModalOpen(false)
                }}
                onAddManually={() => {
                    setEditCropModalOpen(true)
                    setAddCropModalOpen(false)
                }}
            />
            <Modal open={editCropModalOpen} onCancel={() => handleEditModalClose()} onOk={() => handleCropSave()}>
                <h1>{translate(editingCrop ? 'Gewas aanpassen' : 'Nieuw gewas toevoegen')}</h1>
                <Form form={editCropForm} layout="vertical" disabled={reloadingCropData}>
                    <Row gutter={12}>
                        <Col span={12}>
                            <Form.Item name="name" label={translate("Gewas")}>
                                <Input placeholder={translate("Bijv. Rode Biet")}/>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item name="variety" label={translate("Ras")}>
                                <Input placeholder={translate("Bijv. Kogel 2")}/>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            {(!isSeedling) && (
                                <Form.Item name={"is_direct_sown"} className={"mar-b-0"} valuePropName={"checked"}>
                                    <Checkbox onChange={(e) => setIsDirectSown(e.target.checked)}>
                                        Direct zaaien
                                    </Checkbox>
                                </Form.Item>
                            )}
                            {!isDirectSown && (
                                <Form.Item name={"is_seedling"} valuePropName={"checked"}>
                                    <Checkbox onChange={(e) => setIsSeedling(e.target.checked)}>
                                        Pootgoed / plantgoed
                                    </Checkbox>
                                </Form.Item>
                            )}
                        </Col>
                    </Row>

                    {(!isSeedling) && (
                        <Row gutter={12}>
                            <Col span={12}>
                                <Form.Item name="sow_min" label={translate("Zaaien vanaf")}>
                                    <Select options={monthOptions}/>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item name="sow_max" label={translate("Zaaien t/m")}>
                                    <Select options={monthOptions}/>
                                </Form.Item>
                            </Col>
                        </Row>
                    )}
                    {(!isDirectSown) && (
                        <Row gutter={12}>
                            <Col span={12}>
                                <Form.Item name="plant_min" label={translate("Uitplanten vanaf")}>
                                    <Select options={monthOptions}/>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item name="plant_max" label={translate("Uitplanten t/m")}>
                                    <Select options={monthOptions}/>
                                </Form.Item>
                            </Col>
                        </Row>
                    )}
                    <Row gutter={12}>
                        <Col span={12}>
                            <Form.Item name="harvest_min" label={translate("Oogsten vanaf")}>
                                <Select options={monthOptions}/>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item name="harvest_max" label={translate("Oogsten t/m")}>
                                <Select options={monthOptions}/>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={12}>
                        <Col span={12}>
                            <Form.Item name="row_distance" label={translate("Rij afstand")}>
                                <InputNumber addonAfter={"cm"}/>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item name="plant_distance" label={translate("Plant afstand")}>
                                <InputNumber addonAfter={"cm"}/>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={12}>
                        <Col span={12}>
                            <Form.Item name="url" label={translate("URL / link")} >
                                <Input/>
                            </Form.Item>
                            <Tooltip title={translate("Klik op deze knop om de gegevens van het gewas opnieuw op te halen van de website")}>
                            <Button size={"small"} onClick={() => {
                                setReloadingCropData(true)
                                api.getCropInfo({
                                    name: editCropForm.getFieldValue('name'),
                                    variety: editCropForm.getFieldValue('variety'),
                                    url: editCropForm.getFieldValue('url')
                                }).then((crop) => {
                                    editCropForm.setFieldsValue(crop)
                                    setUploadedFile(crop.image)
                                    setIsDirectSown(crop.is_direct_sown)
                                    setIsSeedling(crop.is_seedling)
                                }).finally(() => {
                                    message.success(translate('Gegevens zijn opgehaald!'))
                                    setReloadingCropData(false)
                                })
                            }}>{translate('Gegevens opnieuw ophalen')}</Button></Tooltip>
                        </Col>
                        <Col span={12}>
                            <Form.Item label={translate("Afbeelding")}>
                                <input type="file" onChange={handleUpload}/>
                            </Form.Item>
                            {uploadedFile && <img src={uploadedFile} style={{maxWidth: "100%", maxHeight: '100px'}}
                                                  alt={uploadedFile}/>}
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <Form.Item label={translate('Notitie')} name={"note"}>
                                <TextArea placeholder={translate('Voeg een notitie toe')} rows={4}/>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
            <Row gutter={[12, 12]} className={"mar-b-1"}>
                <Col md={12} xs={12}>
                    <h1>{translate("Gewassen")}</h1>
                </Col>
                <Col md={12} xs={12} style={{textAlign: 'right'}}>
                    <Button type="primary" size={"large"} onClick={() => setAddCropModalOpen(true)}>
                        {translate('Gewas toevoegen')}
                    </Button>
                </Col>
            </Row>


            <Form form={filterCropForm} layout={"vertical"}>
                <Row gutter={[12, 12]}>
                    <Col md={4} xs={12}>
                        <Form.Item name={"search"} label={translate("Naam en/of ras")}>
                            <Input onPressEnter={loadCrops} placeholder={translate("Zoeken op naam en/of ras")}/>
                        </Form.Item>
                    </Col>
                    <Col md={3} xs={12}>
                        <Form.Item name={"sow_in"} label={translate("Zaaiperiode")}>
                            <Select mode={"multiple"}
                                    placeholder={translate("Filter op zaaiperiode")}
                                    defaultValue={[]}
                                    options={monthOptions}
                                    allowClear={true}
                            />
                        </Form.Item>
                    </Col>
                    <Col md={3} xs={12}>
                        <Form.Item name={"plant_in"} label={translate("Plantperiode")}>
                            <Select mode={"multiple"}
                                    placeholder={translate("Filter op (ver)plantperiode")}
                                    defaultValue={[]}
                                    options={monthOptions}
                                    allowClear={true}
                            />
                        </Form.Item>
                    </Col>
                    <Col md={3} xs={12}>
                        <Form.Item name={"harvest_in"} label={translate("Oogstperiode")}>
                            <Select mode={"multiple"}
                                    placeholder={translate("Filter op oogstperiode")}
                                    defaultValue={[]}
                                    options={monthOptions}
                                    allowClear={true}
                            />
                        </Form.Item>
                    </Col>
                    <Col md={4} xs={12}>
                        <Form.Item label={translate("Sorteer")} name={"sort"}>
                            <Select
                                defaultValue={"id_desc"} options={[
                                {
                                    label: translate("Nieuwste eerst"),
                                    value: "id_desc"
                                },
                                {
                                    label: translate("Oudste eerst"),
                                    value: "id_asc"
                                },
                                {
                                    label: translate("Zaaiperiode"),
                                    value: "sow_min_asc"
                                },
                                {
                                    label: translate("Plantperiode"),
                                    value: "plant_min_asc"
                                },
                                {
                                    label: translate("Oogstperiode"),
                                    value: "harvest_min_asc"
                                }
                            ]}></Select>
                        </Form.Item>
                    </Col>
                    <Col xs={12} md={3}>
                        <Form.Item name={"stock_status"} label={translate('Op voorraad')}>
                            <Select
                                defaultValue={"all_stock"} options={[
                                {
                                    label: translate("Alle"),
                                    value: "all_stock"
                                },
                                {
                                    label: translate("Op voorraad"),
                                    value: "in_stock"
                                },
                                {
                                    label: translate("Niet op voorraad"),
                                    value: "no_stock"
                                }
                            ]}></Select>
                        </Form.Item>
                    </Col>
                    <Col md={2} xs={12}>
                        <Form.Item label={<></>}>
                            <Button type={"primary"} onClick={loadCrops}>{translate("Zoeken")}</Button>
                        </Form.Item>

                    </Col>
                    <Col md={2} xs={12}>
                        <Form.Item label={<></>}>
                            <Button onClick={() => {
                                filterCropForm.resetFields()
                                loadCrops()
                            }}>{translate('Reset')}</Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>


            {loading && (
                <Spin/>
            )}

            {(!loading && !isFiltering && crops.length === 0) && (
                <>
                    <Alert
                        description={translate("Je hebt nog geen gewassen in je account staan. Klop op de knop `Gewas toevoegen` om ze direct toe tevoegen! :)")}></Alert>
                    <LoomVideo/>
                </>
            )}
            {(!loading && isFiltering && crops.length === 0) && (
                <Alert
                    description={translate("Helaas, er zijn geen gewassen gevonden die voldoen aan de filters. Probeer het opnieuw met andere filters.")}></Alert>
            )}

            {crops.map((crop) => {
                return (
                    <Row gutter={[16, 16]} className={"crop-row"} key={`crop${crop.id}`}>
                        <Col xs={0} md={4}>
                            <a name={crop.id}/>
                            <CropImage crop={crop} border={true} width={"100%"}/>
                        </Col>
                        <Col xs={24} md={16}>
                            <h2>{crop.name} - {crop.variety}</h2>
                            <p>{translate('Plantafstand:')} {crop.row_distance}x{crop.plant_distance}cm</p>
                            {crop.note && (
                                <div style={{border: 'solid 1px silver', padding: '8px'}}>
                                    {crop.note.split("\n").map((line, index) => <div key={index}>{line}</div>)}
                                </div>
                            )}
                            <CropTiming crop={crop}/>
                            {crop.plantings && crop.plantings.length > 0 && (
                                <>
                                    <h3 className={"mar-t-2"}>{translate('Plantingen')}</h3>
                                    {crop.plantings.filter((p) => p.bed).map((planting) => {
                                        return (
                                            <Button
                                                href={`/garden/${planting.bed.garden_id}/bed/${planting.bed.id}/planting/${planting.id}`}
                                                key={planting.id} size={"small"} className={"mar-r-1"}>
                                                {planting.rows * planting.plants_per_row}x {translate('in bed')} {planting.bed.name} - {translate(PLANTING_STATUS_LABELS[planting.status])}
                                            </Button>
                                        )
                                    })}

                                </>
                            )}
                        </Col>
                        <Col xs={24} md={0} className={"text-center"}>
                            <CropImage crop={crop}/>
                        </Col>
                        <Col style={{textAlign: 'right'}} xs={24} md={4}>
                            <Row>
                                <Col xs={12} md={24}>
                                    <Button size={"small"} onClick={() => handleCropClick(crop)} type="primary"
                                            className={"mar-b-1"}>
                                        <EditOutlined/> {translate('Aanpassen')}
                                    </Button>
                                    {plan.has_team && (
                                        <>
                                            <Button size={"small"} onClick={() => navigate(`/crops/${crop.id}`)}
                                                    type="primary"
                                                    className={"mar-b-1 mar-l-1"}>
                                                <SettingOutlined/> {translate('Geavanceerde instellingen')}
                                            </Button>
                                        </>

                                    )}
                                </Col>
                                <Col xs={12} md={24}>
                                    <Button size={"small"} danger onClick={() => deleteCrop(crop)}
                                            className={"mar-b-1"}>
                                        <DeleteOutlined/> {translate('Verwijderen')}
                                    </Button>
                                </Col>
                                {crop.url && (
                                    <Col xs={12} md={24}>
                                        <Button size={"small"} target="_blank" href={crop.url} className={"mar-b-1"}>
                                            <ExportOutlined/> {translate('Naar website')}
                                        </Button>
                                    </Col>
                                )}
                                <Col xs={12} md={24} className={"mar-b-1"}>
                                    <Switch size={"small"} defaultChecked={crop.in_stock}
                                            onChange={(checked) => toggleStock(crop, checked)}/> {translate('Op voorraad')}
                                </Col>
                            </Row>

                        </Col>
                    </Row>
                )
            })}
        </>
    )
}