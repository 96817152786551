import {Button, Col, Form, Input, message, notification, Row} from "antd";
import React from "react";
import api from "../../api";
import {useTranslations} from "../../lang";
import {useNavigate} from "react-router-dom";

export default function ForgotPassword() {

    const {translate} = useTranslations()
    const [notificationApi, contextHolder] = notification.useNotification();
    const navigate = useNavigate()

    const onFinish = (values) => {

        api.requestPasswordReset(values.email).then((response) => {

        }).finally(() => {
            notificationApi.info({
                message: translate(`Wachtwoord resetten`),
                description: translate('We hebben je een email gestuurd met een link om je wachtwoord opnieuw in te stellen.'),
                placement: 'top',
                duration: 3
            })
        })
    }

    return (
        <>
            <Form
                name="register_form"
                className="register-form"
                initialValues={{
                    remember: true,
                }}
                onFinish={onFinish}>
                <Row justify="center">
                    {contextHolder}
                    <Col md={6} xs={24}>
                        <h1>{translate('Wachtwoord resetten')}</h1>
                        <p>{translate('Vul hieronder je emailadres in, dan ontvang je een email met daarin een link om je wachtwoord opnieuw in te stellen.')}</p>
                        <Form.Item
                            name="email"
                            rules={[
                                {
                                    required: true,
                                    message: translate('Vul een emailadres in'),
                                },
                                {
                                    type: 'email',
                                    message: translate('Vul een geldig emailadres in'),
                                },
                            ]}
                        >
                            <Input placeholder="E-mail address"/>
                        </Form.Item>

                    </Col>
                </Row>
                <Row>
                    <Col span={24} className={"text-center"}>
                        <Form.Item>
                            <Button type="primary" size={"large"} htmlType="submit" className="login-form-button">
                                {translate('Wachtwoord resetten')}
                            </Button>
                        </Form.Item>
                    </Col>
                </Row>

                <Row>
                    <Col span={24} className={"text-center"}>
                        <Button type={"link"} onClick={() => navigate('/login')}>{translate('Terug naar inloggen')}</Button>
                    </Col>
                </Row>
            </Form>
        </>
    );
}