import React from 'react';
import {useTranslations} from "../../lang";
import {Checkbox, Col, Input, Row, Select, Table} from "antd";
import {DeleteRowOutlined} from "@ant-design/icons";

export default function Tasks() {
    const {translate} = useTranslations()

    const columns = [
        {
            title: 'Tuin',
            dataIndex: 'garden',
            key: 'garden',
        },
        {
            title: 'Bed',
            dataIndex: 'bed',
            key: 'bed',
        },
        {
            title: 'Taak',
            dataIndex: 'task',
            key: 'task',
            render: (text, record) => (
                <Checkbox>{text}</Checkbox>
            )
        },
        {
            title: 'Wat',
            dataIndex: 'crop',
            key: 'crop',
        },
        {
            title: 'Wanneer',
            dataIndex: 'date',
            key: 'date',
        },
        {
            title: 'Notities',
            dataIndex: 'notes',
            key: 'notes',
        },

        {
            title: 'Wie',
            render: (text, record) => (
                <Select style={{width: '150px'}} defaultValue={"Stef"} options={[
                    {
                        label: 'Stef',
                        value: 'Stef'
                    },
                    {
                        label: 'Jeroen',
                        value: 'Jeroen'
                    }
                ]}>
                </Select>
            )
        },
    ]

    const data = [
        {task: 'Zaaien', assignee: 'Stef', date: 'Gisteren!', crop: 'Zomerwortel - nantes 2', bed: 'Bed 1', garden: 'Tuin 1', notes: 'Zaaien in rijtjes'},
        {task: 'Zaaien', assignee: 'Stef', date: 'Vandaag', crop: 'Zomerwortel - nantes 2', bed: 'Bed 2', garden: 'Tuin 1', notes: 'Zaaien in rijtjes'},
        {task: 'Verspenen', assignee: 'Stef', date: 'Vandaag', crop: 'Courgette', bed: 'Bed 4', garden: 'Tuin 1', notes: ''},
    ]

    return (
        <div>
            <h1>{translate('Mijn takenlijst')}</h1>
            <p>
                Hier komt een mooie takenlijst met:
            </p>
            <ul>
                <li>Vinkje helemaal links</li>
                <li>Kunnen filteren op tuin</li>
                <li>Kunnen filteren op wie, ook "mijn taken" kunnen kiezen</li>
                <li>Kunnen filteren op date range / wanneer</li>
                <li>Kunnen filteren op type taak (bijv. alleen zaaien voor als je in de kas staat)</li>
            </ul>
            <Table columns={columns} dataSource={data}>

            </Table>
            <Row>
                <Col span={8}><Input /></Col>
                <Col span={8}>
            <Select style={{width: '100%'}} options={[
                {
                    value: 'before',
                    label: 'dagen voor zaaien'
                },
                {
                    label: 'after',
                    value: 'dagen na zaaien'
                }
            ]}></Select></Col>
                <Col span={8}>
            <Input /></Col>
            </Row>

        </div>
    );
}