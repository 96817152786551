import React, {useEffect, useState} from "react";

function CropImage({crop, border = false, width = "100%", height = null}) {
    const defaultImage = "/static/img/default-crop-image.png";
    const [currentSrc, setCurrentSrc] = useState(crop?.image || defaultImage);
    const [error, setError] = useState(false);

    useEffect(() => {
        if(!crop) {
            return
        }
        if(crop.image) {
            setCurrentSrc(crop.image);
        }
    }, [crop]);

    const handleError = () => {
        if (!error) {
            setError(true);
            setCurrentSrc(defaultImage);
        }
    };

    if(!crop) {
        return null;
    }

    return (
        <div
            style={{
                width: width,
                height: height || "auto",
                overflow: "hidden",
                position: "relative",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                border: border ? "1px solid silver" : null,
            }}
        >
            <img
                key={currentSrc} // Forces re-render when src changes
                src={currentSrc}
                alt={crop.name}
                onError={handleError}
                style={{
                    width: "100%",
                    height: height ? "100%" : "auto",
                    objectFit: "cover",
                }}
            />
        </div>
    );
}

export default CropImage;