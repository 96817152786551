import React, {createContext, useContext, useState} from "react";
import api from "./api";

const AuthContext = createContext();

export const AuthProvider = ({children}) => {

    const [loaded, setLoaded] = useState(false);
    const [company, setCompany] = useState({});
    const [plan, setPlan] = useState({});
    const [user, setUser] = useState({});

    if (!loaded && api.isLoggedIn()) {
        api.get_plan().then(setPlan)
        api.getMyCompany().then(setCompany)
        api.getMe().then((u) => {
            console.log('Setting user id.', u.id)
            window.clarity("consent")
            window.clarity("identify", u.id.toString());
            setUser(u)
        })
        setLoaded(true)
    }

    return (
        <AuthContext.Provider value={{user, setUser, plan, setPlan, company, setCompany}}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuthState = () => {
    const context = useContext(AuthContext);
    if (!context) throw new Error("useTranslationState must be used within a TranslationProvider");
    return context;
};

export const useAuth = () => {
    const {user, plan, company} = useAuthState();
    return {
        user,
        plan,
        company,
    };
};