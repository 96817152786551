import React, {useEffect, useRef, useState} from 'react'
import {Button, Col, Form, Input, message, Modal, Row, Table} from "antd";
import {useForm} from "antd/es/form/Form";
import api from "../../api";
import {useTranslations} from "../../lang";

const Team = () => {

    const [inviteModalOpen, setInviteModalOpen] = useState(false)
    const [teamMembers, setTeamMembers] = useState([])
    const [addTeamMemberForm] = useForm()
    const [uploadingAvatarTeamMember, setUploadingAvatarTeamMember] = useState(null)
    const {translate} = useTranslations()

    const fileInputRef = useRef(null);

    useEffect(() => {
        // Fetch team members
        api.getTeamMembers().then(setTeamMembers)
    }, []);

    const cancelInvite = () => {
        setInviteModalOpen(false)
        addTeamMemberForm.resetFields()
    }

    const deleteTeamMember = (teamMember) => {
        Modal.confirm({
            title: translate('Weet je het zeker?'),
            content: translate('Als je iemand deactiveert, kan hij/zij niet meer inloggen en gebruikmaken van de app. Dit kan ook niet ongedaan worden gemaakt.'),
            onOk: () => {
                api.deactivateTeamMember(teamMember.id).then(() => {
                    api.getTeamMembers().then(setTeamMembers)
                    message.success(translate('Je teamlid is gedeactiveerd.'))
                });
            },
            okText: translate('Ja, deactiveren'),
            cancelText: translate('Annuleren')
        });
    }

    const inviteTeamMember = () => {
        addTeamMemberForm.validateFields().then(values => {
            api.addTeamMember(values.name, values.email).then((response) => {
                setInviteModalOpen(false)
                addTeamMemberForm.resetFields()
                api.getTeamMembers().then(setTeamMembers)
                message.success(translate('Je teamlid is uitgenodigd! We hebben ze een email gestuurd :-)'))
            }).catch((error) => {
                message.error(translate('Er is iets misgegaan bij het uitnodigen van je teamlid. Probeer het later nog eens.'))
            })
        })
    }

    const uploadAvatar = (teamMember) => {
        setUploadingAvatarTeamMember(teamMember)
        fileInputRef.current.click();
    }


    const handleAvatarUpload = (event) => {
        const file = event.target.files[0];
        if (file) {
            api.uploadToCdn(file).then((response) => {
                api.updateTeamMember(uploadingAvatarTeamMember.id, {avatar: response}).then(() => {
                    message.success(translate('Avatar geüpload!'))
                    api.getTeamMembers().then(setTeamMembers)
                })
            })
        }
    };

    return (
        <div>
            <h1>{translate('Mijn Team')}</h1>
            <Modal open={inviteModalOpen}
                   title={translate('Nieuw teamlid uitnodigen')}
                   onCancel={cancelInvite}
                   onOk={inviteTeamMember}
                   okText={translate('Uitnodigen')}
                   cancelText={translate('Annuleren')}
            >
                <Form form={addTeamMemberForm} layout={"vertical"}>
                    <p>{translate('Het uitnodigen van een (nieuw) teamlid werkt als volgt:')}</p>
                    <ol>
                        <li>
                            {translate('Vul hieronder de gegevens van het nieuwe teamlid dat je wilt uitnodigen.')}
                        </li>
                        <li>{translate('Na het toevoegen zullen wij een uitnodiging sturen naar het opgegeven e-mailadres.')}</li>
                        <li>{translate('Vervolgens kan het nieuwe teamlid deze accepteren en inloggen.')}</li>
                    </ol>
                    <Form.Item label={translate('Naam')} name={"name"}
                               rules={[
                                   {
                                       required: true,
                                       message: translate('Vul de naam in van je teamlid'),
                                   },
                               ]}>
                        <Input/>
                    </Form.Item>
                    <Form.Item label={translate('E-mailadres')} name={"email"} rules={[
                        {
                            required: true,
                            message: translate('Vul een (geldig) emailadres in'),
                        },
                        {
                            type: 'email',
                            message: translate('Vul een (geldig) emailadres in'),
                        },
                    ]}>
                        <Input type={"email"}/>
                    </Form.Item>
                </Form>
            </Modal>
            <Row>
                <Col span={24}>
                    <Button type={"primary"} onClick={() => setInviteModalOpen(true)} className={"mar-b-1"}>
                        {translate('Teamlid uitnodigen')}
                    </Button>
                    <Table key={"id"} columns={[{
                        title: 'Avatar',
                        key: 'avatar',
                        render: function (teamMember) {
                            return (
                                <>
                                    <img src={teamMember?.avatar ? teamMember.avatar : '/static/img/empty-avatar.png'}
                                         alt={teamMember.name} style={{maxWidth: '48px', borderRadius: '50%'}}/>
                                </>)
                        }
                    }, {
                        title: translate('Naam'),
                        dataIndex: 'name',
                        key: 'name',
                    }, {
                        title: translate('E-mailadres'),
                        render(teamMember) {
                            return !teamMember.is_active && !teamMember.is_invited ? translate('Gedeactiveerd') : teamMember.email
                        }

                    }, {
                        title: translate('Actief?'),
                        render(teamMember) {
                            return teamMember.is_active ? translate('Ja') : translate('Nee')
                        },
                        key: 'is_active',
                    }, {
                        title: '',
                        render(teamMember) {
                            return (
                                <div className={"text-right"}>
                                    <Button size={"small"} className={"mar-r-1"}
                                            onClick={() => uploadAvatar(teamMember)}>
                                        {translate('Avatar uploaden')}
                                    </Button>
                                    <Button onClick={() => deleteTeamMember(teamMember)} size={"small"} danger>
                                        {translate('Deactiveer')}
                                    </Button>
                                </div>
                            )
                        },

                    }]} dataSource={teamMembers.map((item) => ({...item, key: item.id}))}></Table>
                </Col>
            </Row>

            <input
                type="file"
                ref={fileInputRef}
                style={{display: "none"}}
                onChange={handleAvatarUpload}
            />
        </div>
    )
}


export default Team