// Importing necessary components from antd and react packages
import React, {useEffect} from 'react';
import {Form, Input, Button, Row, Col, message} from 'antd';
import api from "../../api";
import {useNavigate, useSearchParams} from "react-router-dom";
import {useTranslations} from "../../lang";

export default function Login() {

    const navigate = useNavigate()
    const [searchParams, setSearchParams] = useSearchParams();
    const {translate} = useTranslations()

    useEffect(() => {
        if (api.isLoggedIn()) {
            window.location.href = '/gardens';
        }

        if(searchParams.get('password_reset') === "1") {
            message.success(translate('Je wachtwoord is succesvol gereset, log nu in met je nieuwe wachtwoord'));
        }
        if(searchParams.get('invite_accepted') === "1") {
            message.success(translate('Je account is aangemaakt! Je kunt nu inloggen :-)'));
        }

    }, []);

    const onFinish = (values) => {
        api.login(values.email, values.password).then((response) => {
            if (response.token) {
                localStorage.setItem('token', response.token);
                window.location.href = '/';
            }
        }).catch(() => {
            message.error(translate('Er is iets misgegaan met inloggen, probeer het opnieuw'));
        });
    };

    return (
        <Row justify="center">
            <Col md={6} xs={24}>
                <h1>{translate('Welkom terug!')}</h1>
                <p>{translate('Log in om verder te gaan')}</p>
                <Form
                    name="login_form"
                    className="login-form"
                    initialValues={{
                        remember: true,
                    }}
                    onFinish={onFinish}
                >
                    <Form.Item
                        name="email"
                        rules={[
                            {
                                required: true,
                                message: translate('Vul je emailadres in!'),
                            },
                        ]}
                    >
                        <Input type={"email"} placeholder="Emailadres"/>
                    </Form.Item>
                    <Form.Item
                        name="password"
                        rules={[
                            {
                                required: true,
                                message: translate('Vul een wachtwoord in!'),
                            },
                        ]}
                    >
                        <Input.Password placeholder={translate('Wachtwoord')}/>
                    </Form.Item>
                    <Row>
                        <Col md={12} xs={12}>
                            <Form.Item>
                                <Button type={"link"} onClick={() => navigate("/forgot-password")}>
                                    {translate('Wachtwoord vergeten?')}
                                </Button>
                            </Form.Item>
                        </Col>
                        <Col md={12} xs={12} className={"text-right"}>
                            <Form.Item>
                                <Button type="primary" htmlType="submit" className="login-form-button">
                                    {translate('Inloggen')}
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Col>
            <Col md={6} xs={24}>
                <div style={{textAlign: 'center'}}>
                    <h1>{translate('Nog geen account?')}</h1>
                    <p>{translate('Wacht niet langer, begin direct!')}</p>
                    <p><Button type={"primary"} size={"large"} onClick={() => navigate("/register")}>{translate('Registreer hier')}</Button></p>
                </div>
            </Col>
        </Row>
    );
};