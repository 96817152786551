import React, {useEffect, useState} from 'react'
import api from "../../api";
import {Button, Col, Form, Input, Modal, Row} from "antd";
import TextArea from "antd/lib/input/TextArea";

export default function AdminQuestions() {

    const [openQuestions, setOpenQuestions] = useState([])
    const [answerModalOpen, setAnswerModalOpen] = useState(false)
    const [questionToAnswer, setQuestionToAnswer] = useState(null)
    const [questionThread, setQuestionThread] = useState([])
    const [form] = Form.useForm()

    useEffect(() => {
        api.getOpenQuestions().then(setOpenQuestions)
    }, []);

    function textToLines(text) {
        if(!text) return null
        return text.split("\n").map((line, index) => (
            <div key={index}>{line}</div>
        ))
    }

    return (
        <>
            <h1>Open vragen</h1>

            <Modal width={"80%"} open={answerModalOpen} onCancel={() => {
                setAnswerModalOpen(false)
                form.resetFields()
                setQuestionToAnswer(null)
            }} onOk={() => {
                api.answerQuestion(questionToAnswer.id, form.getFieldValue('answer'), form.getFieldValue('loom_video')).then(() => {
                    api.getOpenQuestions().then(setOpenQuestions)
                    setQuestionToAnswer(null)
                    setAnswerModalOpen(false)
                    form.resetFields()
                })
            }}>

                {questionToAnswer && (
                    <>
                        <h1>{questionToAnswer?.user?.name} - {questionToAnswer?.user?.email}</h1>
                        {questionThread.map((q) => {
                            return (
                                <div key={q.id}>
                                    <p style={{border: 'solid 1px silver', padding: '5px'}}>{q?.user?.name}: {textToLines(q.question)}</p>
                                    <p>Stef:{textToLines(q.answer)}</p>
                                </div>
                            )
                        })}
                    </>
                )}

                <Form form={form} layout={"vertical"}>
                    <Form.Item name={"answer"} label={"Answer"}>
                        <TextArea rows={4}/>
                    </Form.Item>
                    <Form.Item name={"loom_video"} label={"Loom URL"}>
                        <Input/>
                    </Form.Item>
                </Form>
            </Modal>

            {openQuestions.map((q) => (
                <Row key={q.id} style={{borderTop: 'solid 1px black', padding: "8px  0"}}>
                    <Col span={22}>
                        <h1>{q.user.name}</h1>
                        <pre>{q.question}</pre>
                    </Col>
                    <Col span={2}>
                        <Button type={"primary"} onClick={() => {
                            setAnswerModalOpen(true)
                            setQuestionToAnswer(q)
                            api.getQuestionThread(q.user_id).then(setQuestionThread)
                        }}>Answer</Button>
                        <Button type={"primary"} href={`/admin/impersonate/${q.user_id}`}>Impersonate</Button>
                    </Col>
                </Row>
            ))}
        </>
    )
}