import React, {useEffect} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import {Button, Checkbox, Col, Form, Input, InputNumber, message, Modal, Row, Select} from "antd";
import {monthOptions} from "../../constants";
import TextArea from "antd/lib/input/TextArea";
import {useTranslations} from "../../lang";
import api from "../../api";
import CropImage from "../../Components/CropImage";
import {calculateCropDates} from "../../helpers";
import CropTiming from "../../Components/CropTiming";

export default function EditCrop() {
    const {id} = useParams()
    const {translate} = useTranslations()
    const navigate = useNavigate()

    const [crop, setCrop] = React.useState(null);
    const [uploadedFile, setUploadedFile] = React.useState(null);

    const [isDirectDown, setIsDirectDown] = React.useState(false);
    const [isMultipleHarvestable, setIsMultipleHarvestable] = React.useState(false);
    const [isPrickedOut, setIsPrickedOut] = React.useState(false);
    const [calculationExample, setCalculationExample] = React.useState(null);

    const [form] = Form.useForm();

    function handleUpload(event) {
        event.preventDefault();
        api.uploadToCdn(event.target.files[0]).then(setUploadedFile);
    }

    function calculateCropDaysFromCalendar() {
        form.setFieldValue('germination_days', 7)
        form.setFieldValue('plant_out_days', (crop.plant_min - crop.sow_min) * 30)
        form.setFieldValue('maturity_days', (crop.harvest_min - crop.plant_min) * 30)

    }

    function handleCropSave() {
        let values = form.getFieldsValue()
        if (crop === null) {
            values.image = uploadedFile
            api.createCrop(values).then((c) => {
                navigate(`/crops#${c.id}`)
            }).catch(error => {
                if (error.response.data.error === "CropLimitExceededException") {
                    Modal.error({
                        title: translate("Je hebt het maximum aantal gewassen bereikt"),
                        okText: translate("Ga naar account instellingen"),
                        onOk: () => navigate('/account'),
                        content: (
                            <div>
                                <p>{translate("Je hebt het maximum aantal gewassen bereikt voor je account.")}</p>
                                <p>{translate("Wil je meer gewassen toevoegen? Upgrade dan.")}</p>
                            </div>
                        )
                    })
                    return
                }
            })
        } else {
            values.image = uploadedFile
            values.is_fully_configured = true
            api.updateCrop(id, values).then(() => {
                navigate(`/crops#${id}`)
                message.success(translate('De gegevens van het gewas zijn aangepast!'))
            })
        }
    }

    useEffect(() => {
        if (id === "new") {
            return
        }
        api.getCrop(id).then((c) => {
            setCrop(c)
            form.setFieldsValue(c)
            setUploadedFile(c.image)
            setIsPrickedOut(c.is_pricked_out)
            setIsDirectDown(c.is_direct_sown)
            setIsMultipleHarvestable(c.is_multiple_harvestable)

        })
    }, []);

    return (
        <div>
            <h1>{translate('Gewas instellingen')}</h1>
            <Button onClick={() => handleCropSave()} size={"large"} type={"primary"}
                    className={"mar-b-1"}>Opslaan</Button>
            <Form form={form} layout="vertical">
                <Row gutter={18}>
                    <Col md={8}>
                        <h1>Algemene informatie</h1>
                        <Row gutter={12}>
                            <Col span={12}>
                                <Form.Item name="name" label={translate("Gewas")}>
                                    <Input placeholder={translate("Bijv. Rode Biet")}/>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item name="variety" label={translate("Ras")}>
                                    <Input placeholder={translate("Bijv. Kogel 2")}/>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item name="row_distance" label={translate("Rij afstand")}>
                                    <InputNumber style={{width: '100%'}} addonAfter={"cm"}/>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item name="plant_distance" label={translate("Plant afstand")}>
                                    <InputNumber style={{width: '100%'}} addonAfter={"cm"}/>
                                </Form.Item>
                            </Col>

                            <Col span={12}>
                                <Form.Item name="url" label={translate("URL / link")}>
                                    <Input/>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label={translate("Afbeelding")}>
                                    <input type="file" style={{width: '100%'}} onChange={handleUpload}/>
                                </Form.Item>
                                <CropImage crop={crop} width={"148px"} height={"148px"}/>
                            </Col>
                            <Col span={24}>
                                <h1 className={"mar-t-1"}>Zaaikalender</h1>
                                <CropTiming crop={crop}></CropTiming>
                                <Button onClick={() => calculateCropDaysFromCalendar()}>
                                    Bereken dagen op basis van zaaikalender
                                </Button>

                            </Col>
                        </Row>
                    </Col>
                    <Col md={16} xs={24}>
                        <h1>Zaaien</h1>
                        <Form.Item name={"is_direct_sown"} valuePropName={"checked"}>
                            <Checkbox onChange={(e) => setIsDirectDown(e.target.checked)}>
                                {translate("Dit gewas wordt direct gezaaid")}
                            </Checkbox>
                        </Form.Item>
                        <Row gutter={12}>
                            <Col md={12} xs={12}>
                                <Form.Item name="sow_min" label={translate("Zaaien vanaf")}>
                                    <Select options={monthOptions}/>
                                </Form.Item>
                            </Col>
                            <Col md={12} xs={12}>
                                <Form.Item name="sow_max" label={translate("Zaaien t/m")}>
                                    <Select options={monthOptions}/>
                                </Form.Item>
                            </Col>
                            <Col md={12} xs={24}>
                                <Form.Item name={"germination_days"} label={translate('Dagen van zaaien tot kiemen')}>
                                    <InputNumber placeholder={translate('Bijv. 7')}/>
                                </Form.Item>
                            </Col>
                        </Row>
                        {(!isDirectDown && (
                            <>
                                <h1>Uitplanten & verspenen</h1>

                                <Form.Item name={"is_pricked_out"} valuePropName={"checked"}>
                                    <Checkbox onChange={(e) => setIsPrickedOut(e.target.checked)}>
                                        {translate("Dit gewas moet verspeend/verpot worden")}
                                    </Checkbox>
                                </Form.Item>

                                <Row gutter={12}>
                                    <Col span={12}>
                                        <Form.Item name="plant_min" label={translate("Uitplanten vanaf")}>
                                            <Select options={monthOptions}/>
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item name="plant_max" label={translate("Uitplanten t/m")}>
                                            <Select options={monthOptions}/>
                                        </Form.Item>
                                    </Col>
                                    {isPrickedOut && (
                                        <Col md={12} xs={24}>
                                            <Form.Item name={"prick_out_days"}
                                                       label={translate('Dagen van kiemen tot verspenen/verpotten')}>
                                                <Input placeholder={translate('Bijv. 14')}/>
                                            </Form.Item>
                                        </Col>
                                    )}
                                    <Col md={12} xs={24}>
                                        <Form.Item name={"plant_out_days"}
                                                   label={translate(isPrickedOut ?'Dagen van verspenen tot uitplanten' : 'Dagen van kiemen tot uitplanten')}>
                                            <Input placeholder={translate('Bijv. 60')}/>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </>
                        ))}
                        <h1>Oogsten</h1>

                        <Form.Item name={"is_multiple_harvestable"} valuePropName={"checked"}>
                            <Checkbox onChange={(e) => setIsMultipleHarvestable(e.target.checked)}>
                                {translate("Dit gewas is meerdere keren te oogsten")}
                            </Checkbox>
                        </Form.Item>
                        <Row gutter={12}>
                            <Col span={12}>
                                <Form.Item name="harvest_min" label={translate("Oogsten vanaf")}>
                                    <Select options={monthOptions}/>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item name="harvest_max" label={translate("Oogsten t/m")}>
                                    <Select options={monthOptions}/>
                                </Form.Item>
                            </Col>
                            <Col md={12} xs={24}>
                                <Form.Item name={"maturity_days"}
                                           label={translate(isDirectDown ? 'Aantal dagen van kiemen tot oogst' : 'Dagen van uitplanten tot oogst')}>
                                    <InputNumber placeholder={translate('Bijv. 60')}/>
                                </Form.Item>
                            </Col>
                            {isMultipleHarvestable && (
                                <>
                                    <Col span={8}>
                                        <Form.Item name={"harvest_days"}
                                                   label={translate('Aantal dagen tussen oogsten')}>
                                            <InputNumber placeholder={translate('Bijv. 7')}/>
                                        </Form.Item>
                                    </Col>
                                    <Col span={8}>
                                        <Form.Item name={"harvest_count"} label={translate('Aantal keer te oogsten')}>
                                            <InputNumber placeholder={translate('Bijv. 3')}/>
                                        </Form.Item>
                                    </Col>
                                </>
                            )}

                            <Col span={24}>
                                <Form.Item>
                                    <Button onClick={() => {
                                        const cropData = {
                                            ...form.getFieldsValue(),
                                            ...{is_fully_configured: true}
                                        }
                                        const d = calculateCropDates(cropData)
                                        console.log(d)
                                        setCalculationExample(d)
                                    }}>Toon rekenvoorbeeld</Button>
                                </Form.Item>
                            </Col>
                            <Modal footer={null} open={calculationExample !== null} onCancel={() => setCalculationExample(null)}>
                                <h1>Rekenvoorbeeld</h1>
                                <table style={{width: '100%'}}>
                                    <tbody>
                                    <tr>
                                        <th>Zaaien</th>
                                        <td>{calculationExample?.sow_at.format('DD MMM')}</td>
                                    </tr>
                                    <tr>
                                        <th>Kiemen</th>
                                        <td>{calculationExample?.germinate_at.format('DD MMM')}</td>
                                        <td>+{form.getFieldValue('germination_days')} dagen</td>
                                    </tr>
                                    {!isDirectDown && (
                                        <>
                                            {isPrickedOut && (
                                                <tr>
                                                    <th>Verspenen</th>
                                                    <td>{calculationExample?.prick_at.format('DD MMM')}</td>
                                                    <td>+{form.getFieldValue('prick_out_days')} dagen</td>
                                                </tr>
                                            )}
                                            <tr>
                                                <th>Uitplanten</th>
                                                <td>{calculationExample?.transplant_at.format('DD MMM')}</td>
                                                <td>+{form.getFieldValue('plant_out_days')} dagen</td>
                                            </tr>
                                        </>
                                    )}

                                    {!isMultipleHarvestable && (
                                        <tr>
                                            <th>{'Oogsten'}</th>
                                            <td>{calculationExample?.harvest_at.format('DD MMM')}</td>
                                            <td>+{form.getFieldValue('maturity_days')} dagen</td>
                                        </tr>
                                    )}
                                    {calculationExample?.harvest_dates.map((date, index) => (
                                        <tr key={index}>
                                            <th>Oogst {index + 1}</th>
                                            <td>{date.format('DD MMM')}</td>
                                            <td>+{isMultipleHarvestable && index == 0 ? form.getFieldValue('maturity_days') : form.getFieldValue('harvest_days')} dagen</td>
                                        </tr>
                                    ))}
                                    {isMultipleHarvestable && (
                                        <tr>
                                            <th>{isMultipleHarvestable ? 'Laatste oogst' : 'Oogsten tot'}</th>
                                            <td>{calculationExample?.harvest_until.format('DD MMM')}</td>
                                        </tr>
                                    )}
                                    </tbody>
                                </table>
                            </Modal>
                        </Row>
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <Form.Item label={translate('Notitie')} name={"note"}>
                        <TextArea style={{width: '100%'}} placeholder={translate('Voeg een notitie toe')} rows={4}/>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </div>
    )
}