import React from 'react';

const PlantingPreview = ({ bedWidth, bedLength, numRows, plantsPerRow, label }) => {
    const xmlns = "http://www.w3.org/2000/svg";
    const svgWidth = bedWidth;
    const svgHeight = bedLength;
    const rowSpacing = bedLength / (numRows + 1);
    const plantSpacing = bedWidth / (plantsPerRow + 1);

    const circlesAndLines = [];

    for (let row = 1; row <= numRows; row++) {
        let prevCx = null;
        let prevCy = null;

        for (let plant = 1; plant <= plantsPerRow; plant++) {
            let cx = plant * plantSpacing;
            let cy = row * rowSpacing;

            circlesAndLines.push(
                <circle key={`circle-${row}-${plant}`} cx={cx} cy={cy} r={2} fill="black" />
            );

            if (prevCx !== null && prevCy !== null) {
                circlesAndLines.push(
                    <line key={`line-${row}-${plant}`} x1={prevCx} y1={prevCy} x2={cx} y2={cy} stroke="black" strokeWidth={1} />
                );
            }

            prevCx = cx;
            prevCy = cy;
        }
    }

    return (
        <svg xmlns={xmlns} width={svgWidth} height={svgHeight} viewBox={`0 0 ${svgWidth} ${svgHeight}`}>
            <rect x={0} y={0} width={svgWidth} height={svgHeight} fill="none" stroke="black" strokeWidth={1} />
            {circlesAndLines}
            {label && (
                <text x="50%" y="25%" textAnchor="middle" dominantBaseline="middle" fontSize="14" fill="black">
                    {label}
                </text>
            )}
        </svg>
    );
};

export default PlantingPreview;